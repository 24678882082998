import Navbar from '../Navbar/Navbar'
import './Header.css'
import logo from '../../assets/logo.jpg';

const Header = () => {
    ;
    return (
        <header className='header center'>
                <a href='#top' className='headerLink'>
                    <img src={logo} className='headerLogo' alt='logo'/>
                </a>
                <a href='#top' className='headerLinkLabel'>
                    <h3>
                        Tomasz Giaro
                    </h3>
                </a>

                <Navbar/>
        </header>
    )
}

export default Header
