import './Program.css'
import Collapsible from "react-collapsible";

const Program = () => {
    const p1 = (<span className='paragraph'>
        <p>Szanowni czytelnicy!</p>
        <p className='paragraph'> Proszę wybaczyć, że niekiedy będę nawiązywał do przeszłości, ale życie uniwersyteckie nie zna „punktu zero”, w którym mogłoby się zacząć „od nowa”. Powiem więc na początek, że z tzw. reformy Gowina Wydział Prawa i Administracji UW wyszedł w miarę obronną ręką, zwłaszcza jeśli porównamy naszą sytuację z niektórymi innymi uczelniami, w których zamiast – a właściwie obok – wydziałów prawa pojawiły się twory typu instytut prawa, wyposażone na ogół w kompetencje „czysto badawcze”. W czasie, w którym ówczesne władze Uniwersytetu, korzystając z uprawnień przyznanych przez ministerstwo, dążyły do praktycznej likwidacji autonomii wydziałów, współtworzyłem grupę wokół obecnego JM Rektora, której udało się obronić ich względną autonomię, a w tym i autonomię WPiA.
        <p>Na tym nie skończyły się jednak wyzwania, z którymi nadal będziemy musieli się mierzyć na szczeblu ogólnouniwersyteckim, mimo że nie są one już natury politycznej, lecz wiążą się raczej z właściwościami i klasyfikacją nauk. Budżet naszego Wydziału oparty jest – w przeciwieństwie do reprezentujących „twardą” naukę wydziałów fizyki, chemii czy matematyki – nie o przychody z komercjalizacji badań, lecz o nasze świadczenia dydaktyczne. Naszym głównym zadaniem było i jest nadal kształcenie prawników, jednak okolicznością nową i w pewnym zakresie trochę niewygodną stał się status naszej uczelni jako uniwersytetu badawczego.</p>
        <p>Oznacza to pewne rozczarowanie dla tych optymistów, którzy myśleli, że ewaluacja oparta na kryteriach nie do końca jasnych i ulegających zmianom w trakcie okresu oceny minie jak zły sen. Tymczasem już żyjemy w następnym okresie ewaluacji, mimo że jeszcze nie znamy jej zasad. Jednakże nie pozostawimy naszych pracowników bez pomocy. Zapewne trzeba będzie znowu powołać Zespół ds. Ewaluacji, który przygotuje do niej całą dyscyplinę nauk prawnych. Będzie on powołany, gdy tylko zostaną opublikowane ostateczne zasady, by zawczasu się do niej przygotować. Sprowadzi się to do zdopingowania do przygotowania publikacji osób, które miały kłopot z wypełnieniem niezbędnej ilości slotów publikacyjnych.</p>
        <p>Poprzez Sekcję Rozwoju dbamy i będziemy dbali o lepszą obsługę organizacyjno-finansową pracowników podejmujących ambitne projekty badawcze. Zwiększymy dofinansowania publikacji naukowych naszych pracowników, udostępniając środki na tzw. proofreading i inne kosztowne operacje, których wymagają teksty składane do wysoko punktowanych czasopism. Finansujemy zwłaszcza niebagatelne koszty tzw. Open Access, w związku z czym nie jest mi znana sytuacja, w której ktokolwiek z naszych kolegów napisał znakomitą, a nawet tylko dobrą pracę naukową, która nie została opublikowana z przyczyn finansowych. Będziemy dalej zabiegać u JM Rektora o zwiększenie możliwości pobierania na te cele środków z tzw. pozostałości, która stanowi nasz żelazny fundusz na koncie Uniwersytetu.</p>
        <p>Będziemy też nadal walczyć o to, by zmniejszyć obciążenie Wydziału odpisami na rzecz centrali uczelni, tak by środki wypracowane przez nasze studia podyplomowe, niektóre szkoły prawa obcego, kursy doskonalenia zawodowego itd. pozostawały w większym niż dotychczas zakresie do dyspozycji samego Wydziału. Jesteśmy w trakcie modernizowania technicznego aparatu redakcyjnego naszego szacownego czasopisma wydziałowego „Studia Iuridica”, najstarszego spośród aktualnie wydawanych w Polsce czasopism prawniczych, czego efektem będzie – jak mamy nadzieję – jego indeksowanie w bazie Scopus.</p>
        <p>Wracając do tematu uczelni badawczej, zauważmy, że z doskonałością badawczą wiąże się umiędzynarodowienie uniwersytetu, gdyż dobre wyniki naukowe nie powstają w izolacji. Z drugiej strony, umiędzynarodowienie znaczy wzrost mobilności, czyli wymiany zagranicznej. Wymiana akademicka jest zresztą już od dawna stałym elementem zarówno kształcenia (programy Erasmus), jak i pracy naukowo-badawczej. Żadna krótsza lub dłuższa turystyka naukowa nie zastąpi jednak trwałego zatrudniania zagranicznych uczonych na WPiA. Dopiero wtedy będą oni w stanie przekazać nam istotniejsze impulsy. W tym zakresie doświadczeniem pionierskim było przeprowadzone w upływającej kadencji zatrudnienie prof. Beryl ter Haar (Holandia) w dziedzinie prawa pracy i prof. Jakoba Fortunata Stagla (Austria) w dziedzinie historii prawa, który na naszym Wydziale realizuje najbardziej prestiżowy grant NCN Maestro.</p>
        <p>W interesującym wielu z nas temacie finansowania przez Wydział krótkich naukowych wyjazdów zagranicznych zwiększymy limity finansowania z dotychczasowych 2000 do 3000 PLN z uwagi na zmianę kosztów podróży. Mimo że Wydział nie jest formalnie zobowiązany do finansowania podróży naukowych doktorantek i doktorantów, pozostających pod opieką szkół doktorskich, musimy wypracować rozwiązania również w tym sektorze, np. dopuszczając – w ramach wsparcia Wydziału dla budowania relacji mistrz-uczeń – finansowanie wyjazdów doktorantek i doktorantów towarzyszących promotorowi, zwłaszcza w podróżach po Europie. To samo dotyczy wyjazdów studentek i studentów z opiekunami naukowymi, zwłaszcza na moot courts.</p>
        <p>Autorzy programów działania dla wydziałów uniwersyteckich zastanawiają się – z reguły osobno – jak podnieść poziom nauki, jak usprawnić dydaktykę, jak umiędzynarodowić Wydział. Tymczasem na wymienione aspekty należy spojrzeć całościowo. I tak nasze kształcenie prawników jest nieco anachroniczne: naszemu programowi studiów brak przejrzystości i struktury. Stąd często „nie pasujemy” do projektów międzynarodowych lub nie potrafimy się z partnerami zagranicznymi „dogadać”. Podobnie ma się sprawa z nauką, która powinna organicznie wyrastać z dobrej, kompatybilnej międzynarodowo dydaktyki. Musimy zatem rozwijać inicjatywy, które angażują pracowników, doktorantów i studentów.</p></p>
    </span>);
    const p2 = (<span className='paragraph'>
        <p>W tym zakresie chciałbym wspomnieć o dwóch inicjatywach, które chciałbym rozwijać w nadchodzącej kadencji. Pierwsza z nich to projekt European Law School (ELS), powstały w 2007 r. na uniwersytetach Humboldta w Berlinie, Panthéon-Assas w Paryżu i King’s College w Londynie. Później dołączyły Rzym, Amsterdam, Ateny, Lizbona i Madryt (Autonoma). Chodzi o wykształcenie w kilku systemach prawnych, zwieńczone dyplomem „Prawnika Europejskiego”. Składa się on z dyplomu uczelni macierzystej i dodatkowo dwóch innych, na których studiuje się w języku angielskim po ukończeniu trzech lat nauki w domu. W ten sposób ELS tworzy europejską kadrę prawników dla administracji unijnej i rządowej państw członkowskich, korporacji prawniczych oraz nauki. Projekt ELS został podjęty już kilka lat temu, lecz niestety zatrzymany przez pandemię. Obecnie jestem po wznowieniu rozmów z prof. S. Grundmannem (Berlin) i prof. E. Chevreau (Paryż II), którzy przewodzą tej inicjatywie, mimo że potrzeba zgody wszystkich partnerów. Z rozmów tych wynika, że początek uczestnictwa naszych studentów nastąpi w roku akademickim 2025/2026.</p>
        <p>Wspomniane rozmowy dały asumpt do nawiązania współpracy z Uniwersytetem Panthéon-Assas w ramach domeny naukowo-badawczej Sojuszu 4EU+. Sojusz ten uchodzi u nas niesłusznie za projekt czysto dydaktyczny, zwłaszcza dlatego, że niektórzy z pracowników WPiA UW już świadczą swoje wykłady na jego rzecz. Jednak jeszcze przed naszym startem w ELS zostaliśmy zaproszeni do współpracy w projektach naukowo-badawczych z Uniwersytetem Panthéon-Assas. Znalezienie trzeciego partnera pozwoli uruchomić środki dostępne w ramach Sojuszu 4EU+. Tymczasem zaprosiłem prof. Chevreau na nasz flagowy międzynarodowy wykład im. Leona Petrażyckiego w lutym 2025, a od jutra czekam od Koleżanek i Kolegów na propozycje „trójkowych” projektów badawczych.</p>
        <p>Pozwolę sobie jeszcze wskazać kilka wartych rozwijania podobnych przykładów działań międzynarodowych naszego Wydziału, które pochodzą z nieodległej przeszłości:</p>
        <p className='point'>a) &emsp; Centre for International and European Labour Law Studies, kierowane przez prof. Beryl ter Haar, początkowo z Uniwersytetu w Lejdzie, zatrudnioną u nas już od lutego 2020 jako profesor wizytujący. Centrum pod kierunkiem prof. ter Haar to przede wszystkim instytucja badawcza, której strategia polega na zdobywaniu i realizacji grantów finansowanych ze źródeł zagranicznych, takich jak Międzynarodowa Organizacja Pracy i Unia Europejska. Wśród działań dydaktycznych jednostki na uwagę zasługują szkoły letnie i inne kursy, które ruszą już we wrześniu 2024 r., finansowane przez NAWA w ramach grantu STER, a naszemu Wydziałowi przyniosą konkretne zyski. Prof. ter Haar jest postacią międzynarodową, uczoną, należącą do największych autorytetów ponadnarodowego i europejskiego prawa pracy.</p>
        <p className='point'>b) &emsp; LLM in EU Energy and Climate Law, zainicjowany w 2023 r. przez prof. Adama Szafrańskiego, oferuje zestaw umiejętności i wiedzy niezbędnych do sprostania wyzwaniom transformacji energetycznej. Wykłady zawierają nie tylko dogłębną analizę unijnego prawa energetycznego i klimatycznego, ale także rozszerzone spojrzenie na ekonomiczne, technologiczne, polityczne, a także społeczne i filozoficzne implikacje tego procesu. Holistyczne ujęcie programu umożliwia słuchaczom zrozumienie głównych rozwiązań regulacyjnych unijnej polityki energetyczno-klimatycznej oraz sposobów ich wdrażania przez państwa członkowskie. 2-letni LLM zapewnia w języku wykładowym angielskim wiedzę interdyscyplinarną  zagadnieniach zrównoważonego rozwoju i o funkcjonowaniu sektora energetycznego.</p>
        <p className='point'>c) &emsp; Należy też wspomnieć działający od 2020 r. projekt Prawo polskie na Wschodzie, kierowany przez dr. Jarosława Turłukowskiego z naszego Centrum Badań nad Prawem Europy Wschodniej i Azji Centralnej. Do szkół im. Petrażyckiego w Kijowie oraz im. Waśkowskiego w Odessie dołączyła w 2022 r. Szkoła na Wydziale Prawa Uniwersytetu Mołdawskiego w Kiszyniowie, uruchomiona niejako zamiast szkoły w Mińsku na Białorusi, której otwarcie uniemożliwiły problemy polityczne. Dla szkół opublikowano w 2021 r. (także w PDF) skrypt „Wprowadzenie do prawa polskiego”, gdzie 18 współautorów - pracowników WPiA na 500 stronach przedstawia syntetycznie nasz system prawny. W programie uczestniczyło ok. 50 wykładowców naszego Wydziału, z reguły średniego szczebla, tzn. doktorów i doktorów habilitowanych.</p>
        <p className='point'>d) &emsp; Drugi projekt prowadzony przez dr. Turłukowskiego, Szkoła Prawa Europy Wschodniej i Azji Centralnej, finansowany – podobnie jak poprzedni – ze źródeł zewnętrznych, upowszechnia znajomość kultury prawnej obszaru postradzieckiego. W pierwszej edycji 2021/2022 odbyło się 16 wykładów uczonych zarówno z byłego ZSRR, jak i z Zachodu. Planujemy dalszą współpracę z obszarem postradzieckim. Szczególnie widoczna jest potrzeba intensyfikacji wymiany z Ukrainą, gdzie koniec wojny będzie wiązał się z koniecznością odbudowy – również instytucjonalnej. Udział w tym „nowym Planie Marshalla” będzie mieć zapewne także i Polska, w tym polscy prawnicy. Stąd też planujemy, dzięki współpracy pracowników naszego Wydziału z kolegami ukraińskimi, zorganizowanie kursów podstaw prawa ukraińskiego. Mogłyby być one oferowane nieodpłatnie naszym studentom jako zajęcia specjalizacyjne, a także komercyjnie prawnikom-praktykom, co stanowiłoby źródło dochodu Wydziału.</p>
    </span>);
    const p3 = (<span className='paragraph'>
        <p>Wiemy, że pracownicy WPiA nie są zadowoleni z osiąganych poborów. Dlatego w toku ostatniej kadencji duże wysiłki zostały skierowane na poprawienie sytuacji finansowej Wydziału. Kierunek zmian i osiągane sukcesy w tym zakresie wskazują podstawowe dane zawarte sprawozdaniach finansowych WPiA za ostatnie lata. W roku 2020 zakończyliśmy działalność ujemnym wynikiem finansowym w wysokości -2.748.253 zł. i chociaż w następnym roku też wykazaliśmy stratę, ale już we względnie niewielkiej kwocie -148.121 zł. Jeszcze w roku 2022 strata Wydziału wyniosła -1.215.071 zł., ale w 2023 r. odnotowaliśmy wynik dodatni w wysokości 549.610 zł. Warto zauważyć, że nie ograniczono w tym czasie wydatków na niezbędne i celowe prace inwestycyjno-remontowe oraz poprawiono strukturę zatrudnienia poprzez okresową ocenę wykonania tzw. pensów oraz zmniejszenie liczby umów zlecenia na działalność dydaktyczną.</p>
        <p>Warto też zauważyć, że w sytuacjach, w których przewidywana sytuacja finansowa na to pozwalała, a mianowicie w latach 2021 i 2023, podjęta została przez Kolegium Dziekańskie WPiA UW decyzja o wypłatach pracownikom naukowym i administracyjnym nagród Rektora w maksymalnych wysokościach określonych puli środków na ten cel. Nagrody te wypłacane były ze środków WPiA. Dzięki przyjęciu zasady udzielania tylko nagród III stopnia (7.210 zł) liczba pracowników nagrodzonych była stosunkowo wysoka. Nagrody otrzymali także pracownicy administracyjni.</p>
        <p>Również w zakresie wynagrodzeń pracowniczych zapewniliśmy tę samą skalę podwyżek dla wszystkich pracowników, co oznacza, że podwyżkom pracowników finansowanych ze środków budżetowych (subwencja) towarzyszyły podwyżki pracowników naukowo-dydaktycznych, których wynagrodzenia są finansowane środkami własnymi WPiA. Wszyscy otrzymali podwyżkę w wysokości 30%, co w przypadku pracowników finansowanych ze środków własnych oznacza dodatkowy wydatek ponad 39.000 zł miesięcznie nie licząc dodatków: wysługi, „trzynastki” i składki ZUS. Przyznano również stosowną podwyżkę pracownikom administracyjnym. Zapewniło to transparentność polityki Wydziału w zakresie wynagrodzeń.</p>
        <p>W odniesieniu do spraw finansowych należy jeszcze wspomnieć, że już od wielu miesięcy mówi się o konieczności zreformowania „reformy Gowina”, w tym zakresie zwłaszcza o perspektywie zniesienia obowiązku prowadzenia osobnych zajęć dla studentów stacjonarnych i niestacjonarnych. Obowiązek ten nie tylko obciąża Wydział finansowo, lecz ponadto jest niezgodny z naszą tradycją. Na dodatek doprowadził on do zubożenia bogatej oferty programowej, którą Wydział uprzednio zdystansował inne uczelnie, pozwalając młodym naukowcom dzielić się ze słuchaczami najnowszymi wynikami badań.</p>
    </span>);
    const p4 = (<span className='paragraph'>
        <p>Program studiów prawniczych wymaga ciągłego dostosowywania do realiów akademickich i społecznych, lecz również i prawnych. Rok obecny jest okazją do refleksji i ocen, m.in. z uwagi na stwierdzenie nadużyć w uczelniach wyższych sektora prywatnego, czego przykładem jest handel dyplomami na Collegium Humanum. Zdobyliśmy oczywiście pewne doświadczenia w zakresie funkcjonowania warsztatów z prawa procesowego czy pomniejszonych liczebnie wykładów. Wspólnie ze studentami będzie należało dokonać teraz ewaluacji programu i korekt, zmierzających do optymalizacji nauczania.</p>
        <p>Przede wszystkim jednak planuję zniesienie istniejących na kierunku Prawo obok tzw. podstawy programowej bloków wykładów, krępujących tak studentów, jak i wykładowców. System ten nie odpowiada studentom, których ogranicza w wyborze przedmiotów i zmusza do kompromisów polegających na uczestnictwie w niechcianych zajęciach tylko po to, aby wziąć udział w jednych pożądanych. Rodzi to również kłopoty organizacyjne przy układaniu planu zajęć. Sztywne bloki, promowane swego czasu przez ówczesnego prodziekana ds. studenckich, należy zastąpić wykładami specjalizacyjnymi, jednak nie na prostej zasadzie powrotu do przeszłości, kiedy owe wykłady były po prostu wrzucone w sposób nieuporządkowany do jednego worka.</p>
        <p>Korzystając ze wzorów czołowych europejskich wydziałów prawa oraz pierwotnej propozycji powołanego przez mnie przed ośmioma laty zespołu programowego, można by projektować, że obok podstawy programowej student byłby zobowiązany do odbycia elastycznej specjalizacji. W danej specjalizacji, składającej się np. z 6 wykładów, student musiałby zaliczyć tylko 4 z nich, co w przeliczeniu na punkty ECTS oznaczałoby, że – licząc przeciętnie 4 ECTS na przedmiot – wystarczające byłoby uzyskanie 16 punktów ECTS, a jeden wykład mógłby być przypisany nie tylko do jednej specjalizacji. W każdym razie poszczególne specjalizacje, których zaliczenie można by rozciągnąć na 3-4 semestry, mają się układać w programy, ukończenie których powinno kończyć się certyfikatem. W zakresie certyfikowania ukończenia poszczególnych specjalizacji, a może również etapów studiów, możemy prawdopodobnie liczyć na współpracę Ministerstwa Nauki i Szkolnictwa Wyższego.</p>
        <p>Skoro mowa o programie studiów, wspomnijmy następną obok studentek i studentów ofierze „reformy Gowina”, która okazała się operacją „atomizującą” i dzielącą nasze uczelnie na segmenty – doktorantkach i doktorantach. Zostali oni relegowani do osobnych szkół doktorskich, zupełnie jakby się chciało ich odseparować od ich nauczycieli i mistrzów. Oczywiście wielu z nich pozostaje niejako instynktownie w ścisłym kontakcie z własną katedrą. Jednak w obawie o innych, którzy kontakt ten utracili, powinniśmy podejmować aktywniejszy niż dotychczas dialog ze szkołami doktorskimi, zwłaszcza ze Szkołą Doktorską Nauk Społecznych celem wspierania naszych doktorantek i doktorantów.</p>
    </span>);
    const p5 = (<span className='paragraph'>
        <p>Nowa kadencja to nowe wyzwania w zakresie poprawy warunków i jakości studiowania. Dalsze wsłuchiwanie się w głos studencki jest również koniecznością w zakresie ewaluacji pracy pracowników Dziekanatu, jak i personelu nauczającego. W dalszym ciągu będziemy się też musieli mierzyć w pewnym stopniu z nauczaniem zdalnym. Chodzi przy tym o skłonienie wykładowców do atrakcyjnego prowadzenia zajęć, m.in. przy wykorzystaniu możliwości zdalnych platform przy prezentacji, ankiet, testów online, itp. Służyć temu będą środki przeznaczone na rozwój dydaktyki. Wykładowcy zaś będą mogli liczyć na wsparcie osób za dydaktykę odpowiedzialnych.</p>
        <p>Z drugiej strony trzeba wspomóc wszystkie studentki i studentów w trudnych czasach. Wystąpimy do władz Rektorskich o wprowadzenie ułatwień w studiowaniu, w tym o doraźną pomoc dla osób potrzebujących. Wydział rozszerzy też akcję, umożliwiającą studentkom i studentom korzystanie ze zdalnych podręczników, a KJD będzie lepiej monitorować sytuację materialną osób studiujących, wprowadzając elastyczne rozwiązania, pozwalające mniej zamożnym studentkom i studentom podejmowanie i kontynuację nauki na Wydziale. Społeczność studencka pozytywnie przyjęła zainicjowaną podczas mojej ostatniej kadencji współpracę z Wolters Kluwer w zakresie bazy LEX i – w myśl synergii nauki i praktyki – z samorządami zawodowymi, jak np. adwokaci, radcowie prawni czy komornicy. Ta współpraca będzie nadal rozwijana.</p>
        <p>Chciałbym też zasygnalizować dalsze wspieranie działalności studenckich kół naukowych. Niektóre z nich, jak zwłaszcza koło naukowe Prawa Konstytucyjnego „Legislator”, działające pod opieką naukową prof. Marka Zubika, z uwagi na ważką tematykę jego spotkań i konferencji, należą już do zauważalnych aktorów życia naukowego w naszym kraju. Z własnego doświadczenia wiem, że dla przyszłej kariery naukowej ważne są już studenckie początki. Bedę stąd wspierał wszelkie możliwości aktywnego uczestnictwa kół naukowych w nauce polskiej, w tym zwiększę budżet wydziałowej Rady Kół Naukowych, a także dalej zabiegać będę u JM Rektora o zwiększenie dotacji dla ruchu naukowego – zwłaszcza gdy potrzeba środków na współpracę ogólnopolską i międzynarodową.</p>
        <p>Chcąc postawić nasz Wydział na poziomie światowym, widzę potrzebę angażowania społeczności studenckiej w życie naukowe Wydziału nie tylko w ramach zajęć dydaktycznych i kół naukowych. Za przykładem takich partnerów europejskich jak Bonn, ale i uczelni amerykańskich, będę postulował wprowadzenie płatnych praktyk studenckich w ramach katedr. W ten sposób studentki i studenci naszego Wydziału będą wspierać pracowników samodzielnych w pracy organizacyjnej i naukowej. Zamiast słuchać drętwego wykładu w szkole doktorskiej „Jak robić bibliografię naukową?”, student-praktykant zrobi ją raz dla profesora i zapamięta na całe życie. Program płatnych praktyk studenckich pozwoli na wykorzystanie potencjału najmłodszych koleżanek i kolegów, a także posłuży wyłanianiu kandydatów na przyszłe doktorantki i doktorantów prawa.</p>
        <p>Zdecydowanie należy też działać w kierunku zwiększenia przejrzystości udzielania Indywidualnego Toku Studiów i Indywidualnego Toku Sesji. Trzeba wyraźnie określić kryteria przyznawania tej prerogatywy tak, aby uzyskiwały ją przede wszystkim osoby wyróżniające się osiągnięciami w nauce lub takie, których sytuacja życiowa, np. zdrowotna czy studia na kilku kierunkach, tego wymaga. Jeszcze przed wejściem w życie nowego statutu i regulaminu studiów, gdy plenipotencja KJD i kierownika studiów wywodziła się bezpośrednio z kompetencji dziekańskich, na moje polecenie ówczesny kierownik studiów podjął próbę wprowadzenia w tej kwestii maksimum transparentności, lecz po zmianach statutowo-regulaminowych, gdy kompetencja ta przeszła bezpośrednio na KJD, proces ten nie został sfinalizowany. Od nowego KJD będę oczekiwał zobowiązania do przeprowadzenia tej inicjatywy.</p>
        <p>W celu zacieśnienia bieżących kontaktów dziekana ze studentkami i studentami postuluję powołanie przy dziekanie Rady Studenckiej jako ciała doradczego. Widzę potrzebę konsultowania szerokiego grona studenckiego tak, aby móc wsłuchiwać się w ich bieżące potrzeby. Rada składałaby się z 5 przedstawicieli studentek i studentów, w tym przewodniczącego Samorządu Studenckiego WPiA oraz przewodniczącego Rady Kół Naukowych. Pozostali 3 przedstawiciele byliby wybierani w corocznych powszechnych wyborach przez wszystkie studentki i studentów. Z tym postulatem wiąże się plan regularnego, odbywającego się przynajmniej raz w semestrze, spotkania dziekana ze studentami, na którym każdy będzie mógł zabrać głos. Doświadczenie pokazuje, że należy postawić na bezpośrednie spotkanie studentów z dziekanem, gdyż filtr w postaci KJD może prowadzić do zniekształcenia obrazu.</p>
        <p>Wsłuchując się w postulaty studentek i studentów naszego Wydziału widzę również potrzebę wprowadzenia nowych przedmiotów kierunkowych, które będą odpowiadały najnowszym trendom i potrzebom nauki, społeczeństwa i gospodarki. Takimi przedmiotami mogłyby być zapewne materie, których waga we współczesnym świecie wciąż wzrasta: Prawo nowych technologii, Prawo porównawcze i Prawo transnarodowe.</p>
    </span>);
    const p6 = (<span className='paragraph'>
        <p>Będę również dalej działał na rzecz poprawy sytuacji osób z niepełnosprawnościami. Widzę wciąż istniejącą potrzebę nie tylko poprawy infrastruktury dla tych osób, ale również stworzenia regulacji mającej na celu sprzyjanie uproszczonemu i ułatwionemu usprawiedliwianiu ich nieobecności na zajęciach, spowodowanych np. rehabilitacją lub innymi szczególnymi okolicznościami.</p>
        <p>Wydział nasz chce brać czynny udział w tzw. zielonej transformacji. W tych ramach myślimy o zazielenieniu dachu budynku przy ul. Lipowej 4, gdzie można, biorąc przykład z budynku Biblioteki Uniwersyteckiej, dokonać nasadzeń tzw. zieleni niskiej. To jednak powinien być dopiero początek. Warto włączyć się w inicjatywy ogólnouniwersyteckie oraz wyjść z własnymi, które będą prowadziły do bardziej zbilansowanego klimatycznie funkcjonowania Wydziału i Uniwersytetu.</p>
        <p>Nowego otwarcia wymaga współpraca Wydziału z absolwentami. Działające przy Wydziale Stowarzyszenie winno się rozwijać, a Wydział wspierać absolwentów na różnych etapach ich kariery. Budowanie solidarności korporacyjnej ułatwi naszym najmłodszym absolwentkom i absolwentom odnalezienie się na rynku pracy. W tym zakresie WPiA złożył wniosek grantowy programu NAWA Welcome to Poland, którego finansowanie będzie rozstrzygnięte niebawem. Chodzi o budowanie relacji sieciowych z zagranicznymi absolwentami WPiA i przygotowanie bazy danych o ich sukcesach naukowych i zawodowych. Podobne sieci działają na zagranicznych wydziałach prawa, zwłaszcza w USA.</p>
        <p>Do sieci będzie mógł dołączyć każdy polski absolwent WPiA UW, który po ukończeniu studiów wyjechał za granicę na co najmniej pół roku oraz każdy cudzoziemiec-absolwent studiów dowolnego stopnia na WPiA. Do sieci zapraszać będziemy zwłaszcza absolwentów WPiA, którzy kontynuowali naukę na prestiżowych uczelniach zagranicznych, ukończyli np. studia LLM, a potem wrócili do kraju. Zostaną oni ambasadorami sieci zagranicznych absolwentów, a historie ich sukcesu zostaną wykorzystane w celach promocyjnych. Jedną z pierwszych zaproszonych do współpracy będzie A. Mazurowska-Rozdeiczer, obecna prezeska Harvard Club of Poland i absolwentka WPiA oraz Harvard Law School.</p>
        <p>WPiA współpracował już z Harvard Club of Poland, np. przy  organizacji seminarium naukowego The New Silk Road, którego gościem był biegle władający językiem polskim prof. Mark C. Elliott – dobry duch Polonii na Harvard University i jego prorektor ds. współpracy międzynarodowej. W zarządzie Harvard Club of Poland zasiada też dr R. Stroiński, absolwent WPiA i Harvard Law School. Inni absolwenci Harvard Law School pracujący na WPiA to dr O. Kuc, dr K. Tetłak, mgr P. Słup. Absolwentami University of California w Berkeley są pracownicy WPiA prof. J. Jastrzębski, dr M. Bławat i mgr Z. Buszman. Istnieją zatem silne powiązania WPiA z uczelniami amerykańskimi, co stanowi solidną bazę do współpracy z ich absolwentami.</p>
        <p>Rozwijana będzie też współpraca z cudzoziemcami-absolwentami WPiA, mimo że grupa ta nie jest tak liczna jak grupa absolwentów „polskich”. Wypracowany w ten sposób model pozwoli, w skali całej naszej uczelni, na rozszerzenie działań na zagranicznych absolwentów innych wydziałów. Umożliwi to długofalowe umiędzynarodowienie Uniwersytetu Warszawskiego, oparte na systemowych podstawach, zwłaszcza utworzenie na uczelni stałej platformy współpracy z absolwentami zagranicznymi, którzy będą u nas promować stworzone przez siebie innowacyjne rozwiązania i najlepsze praktyki.</p>
        <p>Last, but not least, należy również pamiętać o polityce względem pracowników-seniorów: trzeba uspokoić nasze koleżanki i naszych kolegów, którzy obawiają się, że koniec zatrudnienia pozbawi ich możliwości kontynuowania ich pasji naukowych. Będą oni nadal dysponować dostępem do zasobów bibliotecznych i sieci teleinformatycznej, oraz swego rodzaju naukową „wizytówką”: wydziałowym adresem email i pozostawieniem na stronie Wydziału informacji o afiliacji. Chcemy nadal korzystać z ich wiedzy i doświadczenia.</p>
        <p>Oczywiście, jeśli uprzednio była mowa o poprawieniu komunikacji bezpośrednio między dziekanem i studentami, to samo odnosi się do kontaktów z pracownikami naukowymi. W tym celu chciałbym powołać zespół kierowników katedr i zakładów jako ciało doradcze, umożliwiające wspólne podejmowanie decyzji, poprawę przepływu informacji itd. Poza tym drzwi gabinetu dziekana będą zawsze otwarte dla każdego z Państwa!</p>
    </span>);

    return (
        <section className='section program center' id='program'>
            <h2>Program wyborczy</h2>
            <div className='infoSections'>
                <ProgramSection title='1 Wyzwania nadchodzącej kadencji 2024-2028' content={p1} />
                <ProgramSection title='2 Umiędzynarodowienie Wydziału oraz udział w nim doktorantów i studentów' content={p2} />
                <ProgramSection title='3 Wzmianka o sytuacji finansowej Wydziału' content={p3} />
                <ProgramSection title='4 Optymalizacja programu nauczania ' content={p4} />
                <ProgramSection title='5 Dalsza poprawa jakości studiowania' content={p5} />
                <ProgramSection title='6 Kilka innych ważnych spraw' content={p6} />
            </div>
        </section>
    )
}

const ProgramSection = ({title, content}) => (
    <div className='infoSection'>
        <Collapsible
            trigger={title}
            triggerClassName='infoTrigger'
            triggerOpenedClassName='infoTrigger'
            contentInnerClassName='infoContent'
            contentOuterClassName='infoOuter'
        >
            {content}
        </Collapsible>
    </div>
);

export default Program
