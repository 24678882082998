import './Gallery.css'
import image1 from '../../assets/1.jpg';
import image2 from '../../assets/2.jpg';
import image3 from '../../assets/3.jpg';
import image4 from '../../assets/4.jpg';

const Gallery = () => {
    ;
    return (
        <section className='section gallery center' id='gallery'>
            <h2>Galeria</h2>
            <div className='images'>
                <div className='imageRow'>
                    <div className='imageContainer'>
                        <img src={image1} alt='1' className='image' />
                    </div>
                    <div className='imageContainer'>
                        <img src={image2} alt='2' className='image' />
                    </div>
                </div>
                <div className='imageRow'>
                    <div className='imageContainer'>
                        <img src={image3} alt='3' className='image' />
                    </div>
                    <div className='imageContainer'>
                        <img src={image4} alt='4' className='image' />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Gallery
