import {useState} from 'react'
// import Brightness2Icon from '@material-ui/icons/Brightness2'
// import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
// import {ThemeContext} from '../../contexts/theme'
// import {projects, skills, contact} from '../../portfolio'
import './Navbar.css'
// import "@fontsource/eb-garamond"; // Defaults to weight 400
import "@fontsource/eb-garamond/400.css"; // Specify weight

const Navbar = () => {
    // const [{themeName, toggleTheme}] = useContext(ThemeContext)
    const [showNavList, setShowNavList] = useState(false)

    const toggleNavList = () => setShowNavList(!showNavList)

    return (
        <nav className='center nav'>
            <ul
                style={{display: showNavList ? 'flex' : null}}
                className='nav__list'
            >
                {
                    <li className='nav__list-item'>
                        <a
                            href='#info'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            O mnie
                        </a>
                    </li>
                }
                {
                    <li className='nav__list-item'>
                        <a
                            href='#program'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Program
                        </a>
                    </li>
                }
                {
                    <li className='nav__list-item'>
                        <a
                            href='#publications'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Publikacje
                        </a>
                    </li>
                }
                {
                    <li className='nav__list-item'>
                        <a
                            href='#gallery'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Galeria
                        </a>
                    </li>
                }
                {
                    <li className='nav__list-item'>
                        <a
                            href='#contactFooter'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Kontakt
                        </a>
                    </li>
                }
            </ul>


            <button
                type='button'
                onClick={toggleNavList}
                className='btn btn--icon nav__hamburger'
                aria-label='toggle navigation'
            >
                {showNavList ? <CloseIcon/> : <MenuIcon/>}
            </button>
        </nav>
    )
}

export default Navbar
