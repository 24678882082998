import './Info.css'
import Collapsible from "react-collapsible";

const Info = () => {
    ;
    return (
        <section className='section info center' id='info'>
            <h2>O mnie</h2>
            <div className='infoSections'>
                <div className='infoSection'>
                    <Collapsible
                        trigger='Życiorys'
                        triggerClassName='infoTrigger'
                        triggerOpenedClassName='infoTrigger'
                        contentInnerClassName='infoContent'
                        contentOuterClassName='infoOuter'
                    >
                        <p className='infoContent'>
                            Jestem absolwentem naszego Wydziału Prawa i Administracji UW, który ukończyłem w roku 1972.<br />
                            W latach 1974-75 odbyłem studia podyplomowe w Scuola di Perfezionamento in Diritto Civile na Uniwersytecie w Camerino. Efektem tego i późniejszych pobytów w Camerino były tłumaczenia na język włoski podręczników do polskiego prawa cywilnego „Prawo cywilne. Zarys części ogólnej” Aleksandra Woltera (Jovene Editore 1976) i „Zobowiązania. Zarys wykładu” Witolda Czachórskiego (Edizioni Scientifiche Italiane 1980). Przetłumaczyłem również na język włoski podręcznik „Zarys prawa pracy” Wacława Szuberta; tłumaczenie, ukończone w lecie 1980 r., nigdy nie ukazało się drukiem, gdyż autor w ostatniej chwili wycofał zgodę, jednak jego rękopis jest dostępny w Warszawskiej Bibliotece Uniwersyteckiej. W międzyczasie obroniłem w 1978 r. na naszym Wydziale doktorat, a w 1988 r. uzyskałem habilitację.
                        </p>
                        <p>
                            Od lat 1980-tych zacząłem częściej gościć w Niemczech. Byłem stypendystą Fundacji Humboldta na Wydziale Prawa Uniwersytetu w Bonn (1984-1985), następnie uzyskałem etat profesora w Instytucie Historii Prawa Europejskiego im. Maksa Plancka we Frankfurcie nad Menem (1990-2006). Wykładałem też jako profesor kontraktowy na Uniwersytecie Frankfurckim i na Wolnym Uniwersytecie w Berlinie, a w Giessen prowadziłem wykład zlecony „Historia państwa i prawa rzymskiego”. W Instytucie Maksa Plancka kierowałem międzynarodowymi grantami „Proces modernizacji wschodnioeuropejskich systemów prawnych od początku XIX wieku” (2002-2004) i „Kultura prawna Europy Wschodniej” (2004-2006). Ich wyniki ukazały się w dwóch tomach pod moją redakcją w Wydawnictwie Klostermann (Frankfurt a.M.) w 2007 r., podobnie jak moja monografia „Römische Rechtswahrheiten. Ein Gedankenexperiment“. Z okazji jubileuszu Instytutu planuje się włączenie powyższych publikacji do systemu Open Access.
                        </p>
                        <p>
                            Po powrocie do kraju w latach 2006-2008 zająłem stanowisko profesora wizytującego prawa rzymskiego na Wydziale Prawa i Administracji Uniwersytetu Śląskiego w Katowicach. Nominację profesorską otrzymałem w 2009 r. W latach 2011-2019 byłem członkiem Komitetu Nauk Prawnych PAN. Od 2008 do 2016 roku pełniłem funkcję prodziekana ds. nauki i współpracy zagranicznej Wydziału Prawa i Administracji UW, a w latach 2016-2024 byłem jego dziekanem. W semestrze zimowym roku akademickiego 2010/2011 wykładałem Europejską Tradycję Prawną i przeprowadziłem egzaminy z tego przedmiotu na Wydziale Prawa (Levin College of Law) Uniwersytetu Florydy w Gainesville USA. W roku 2011 otrzymałem nagrodę Fundacji na rzecz Nauki Polskiej w dziedzinie Nauk Humanistycznych i Społecznych.
                        </p>
                    </Collapsible>
                </div>
            </div>
        </section>
    )
}

export default Info
