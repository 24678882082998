import {useContext} from 'react'
import {ThemeContext} from './contexts/theme'
import Header from './components/Header/Header'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import './App.css'
import AboutMe from "./components/AboutMe/AboutMe";
import Program from "./components/Program/Program";
import ContactFooter from "./components/ContactFooter/ContactFooter";
import Info from "./components/Info/Info";
import Gallery from "./components/Gallery/Gallery";
import Publications from "./components/Publications/Publications";
// import "@fontsource/eb-garamond"; // Defaults to weight 400
import "@fontsource/eb-garamond/400.css"; // Specify weight
import "@fontsource/cormorant-garamond"; // Defaults to weight 400

const App = () => {
    const [{themeName}] = useContext(ThemeContext)

    return (
        <div id='top' className={`${themeName} app`}>
            <Header/>

            <main>
                <AboutMe/>
                <Info />
                <Program/>
                <Publications />
                <Gallery />
                <ContactFooter />
            </main>

            <ScrollToTop/>
        </div>
    )
}

export default App
