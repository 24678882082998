import './Publications.css'
import Collapsible from "react-collapsible";
import React, {useState} from 'react';
// import {MobilePDFReader} from 'react-read-pdf';
import {BrowserView, MobileView} from 'react-device-detect';
import pdf from '../../assets/Wykaz-publikacji-Tomasza-Giaro.pdf';

const Publications = () => {
    const content = (<div>
        <div className='publicationsCenter'>
            <h3>
                Wykaz publikacji Tomasza Giaro <br/>
                Do nadania tytułu naukowego profesora
            </h3>
        </div>

        <div className='publicationsCenter'>
            <h4>Monografie</h4>
        </div>
        <p>1. &apos;Excusatio necessitatis&apos; nel diritto romano (Studia Antiqua IX), Warszawa 1982, stron 319</p>
        <p>2. Dogmatische Wahrheit und Zeitlosigkeit in der römischen Jurisprudenz, Bullettino dell&apos;Istituto di
            Diritto Romano 90 (1987) 1-104, nadbitka z indeksem źródeł, Roma 1987, stron 108</p>
        <p>3. Aktualisierung Europas. Gespräche mit Paul Koschaker (Storia delle idee e istituzioni politiche: età
            contemporanea I), Genova 2000, stron 214</p>
        <p>4. Le radici comuni del diritto europeo. Un cambiamento di prospettiva (Studi superiori 490), Roma 2005,
            stron 261 (wspólnie z Pier Giuseppe Monateri i Alessandro Somma)</p>
        <p>5. Römische Rechtswahrheiten. Ein Gedankenexperiment, Frankfurt a.M. 2007, stron 767</p>

        <div className='publicationsCenter'>
            <h4>Artykuły</h4>
        </div>
        <p>6. Własność w Rzymie republikańskim, Czaso­pismo Prawno-Historyczne 25.2 (1973) 231-248</p>
        <p>7. Il limite della responsabilità &apos;ex cautione damni infecti&apos;, Bullettino dell&apos;Istituto di
            Diritto Romano 78 (1975) 271-283</p>
        <p>8. Nowa hipoteza na temat &apos;damni infecti lege agere&apos;, Eos 64 (1976) 91-106</p>
        <p>9. Diritto romano - filosofia e retorica greca, Klio 61 (1979) 97-100</p>
        <p>10. Diritto romano, tradizione romanistica e il concetto di proprietà nel diritto civile socialista, Studia
            Iuridica 12 (1985) 157-180</p>
        <p>11. Über methodologische Werkmittel der Romanistik, Zeitschrift der Savigny-Stiftung RA 105 (1988)
            180-262</p>
        <p>12. Prawda dogmatyczna i ponadczasowość jurysprudencji rzymskiej [= skrócona wersja pozycji nr 2],
            Czaso­pismo Prawno-Historyczne 40.1 (1988) 1-32</p>
        <p>13. Betrachtungen eines trotzigen Konservativen über ein neues Buch von Mario Bretone, Rechtshistori­sches
            Journal 7 (1988) 13-29</p>
        <p>14. Prawda i autorytet w jurysprudencji klasycznej, Schedae Iuridicae Universitatis Jagellonicae 125 (1989)
            29-50</p>
        <p>15. Was hat Cervidius Scaevola nicht geschrieben? oder &apos;elimination seems to be the best
            treatment&apos;, Rechtshistori­sches Journal 8 (1989) 51-60</p>
        <p>16. Temi e prospettive della romanistica contemporanea, Index 18 (1990) 451-457</p>
        <p>17. &apos;De ponte&apos; oder &apos;de monte&apos;? Banalitäten in der römischen Jurisprudenz, Labeo 36
            (1990) 177-217</p>
        <p>18. Romanistische Constructionsplaudereien. Auf den Spuren eines anachronistischen Begriffes,
            Rechtshistorisches Journal 10 (1991) 209-232</p>
        <p>19. Das romanistische Induktionsproblem. Zur Methode der römischen Rechtsfortbildung, Rechtshistorisches
            Jour­nal 10 (1991) 369-395</p>
        <p>20. Fremde in der Rechtsgeschichte Roms, w: M.Th. Fögen (ed.), Fremde der Gesell­schaft, Frankfurt am Main
            1991, 39-57</p>
        <p>21. Das Mehrzweckmodell einer wahrheitsfähigen Rechtsdogmatik, Rechtshistorisches Journal 11 (1992)
            319-329</p>
        <p>22. Von der Genealogie der Begriffe zur Genealogie der Juristen. &apos;De Sabinianis et Proculianis
            fabulae&apos;, Rechtshistorisches Journal 11 (1992) 508-554</p>
        <p>23. Europa und das Pandektenrecht, Rechtshistorisches Journal 12 (1993) 326-345</p>
        <p>24. Die Illusion der Wissenschaftlichkeit, Index 22 (1994) 107-134</p>
        <p>25. Geltung und Fortgeltung des römischen Juristenrechts, Zeitschrift der Savigny-Stiftung RA 111 (1994)
            66-94</p>
        <p>26. L&apos;argumentation dogmatique et l&apos;argumentation scientifique, Rechtshistorisches Journal 13
            (1994) 271-304</p>
        <p>27. Europäische Privatrechtsgeschichte: Werkzeug der Rechtsvereinheitlichung und Produkt der
            Kategorienvermengung, Ius commune 21 (1994) 1-43</p>
        <p>28. Dogmatyka a historia prawa w polskiej tradycji romanistycznej, Prawo Kanoniczne 37.3-4 (1994) 85-99</p>
        <p>29. L&apos;art de comparer les cas, Studia et documenta historiae et iuris 60 (1994) 507-531</p>
        <p>30. La &apos;Civilpolitik&apos; di Petrażycki o dell&apos;amore nel sistema decentralizzato, Index 23 (1995)
            97-157</p>
        <p>31. Echtheitsindizien. Eine Fallstudie zu &apos;verus, verius, verissimus&apos;, Orbis iuris romani 1 (1995)
            78-109</p>
        <p>32. Zivilistik als Geschichte und Theorie, Rechtshistorisches Journal 14 (1995) 345-367</p>
        <p>33. Römisches Recht, Romanistik und Rechtsraum Europa, Ius commune 22 (1995) 1-16</p>
        <p>34. &apos;Lasciamo queste devianze puerili ai tedeschi!&apos;. Dogmatica e storia nella tradizione
            ro­manistica polacca [= rozszerzona wersja pozycji nr 28], Mélanges H. Kupiszewski, Varsovie 1996,
            127-143</p>
        <p>35. Rechtsanwendung, Rechtsfortbildung und römische Rechtsgeschichte, Ricerche dedicate a F. Gallo III,
            Napoli 1997, 493-519</p>
        <p>36. Max Kaser 1906-1997, Rechtshistorisches Journal 16 (1997) 231-357</p>
        <p>37. Alciat starb in der Nacht. Baldus&apos; Schoßhund, Benthams Wachskopf und Grotius&apos; Ein­geweide im
            Kränzchen der deutsch-europäischen Juristenbiographie, Rechtshistorisches Journal 17 (1998) 591-628</p>
        <p>38. Privatrecht als Technik der Gerechtigkeit, w: H. Münkler, M. Llanque (eds.), Konzeptionen der
            Gerechtigkeit, Baden-Baden 1999, 69-80</p>
        <p>39. Aufstieg und Niedergang des sozialistischen Zivilrechts: von der Ideologie zur Rechtsdogmatik der
            Pauschalenteignung, w: G. Bender, U. Falk (eds.), Enteignung, Frankfurt a.M. 1999, 217-317</p>
        <p>40. Die Fiktion des eigentlichen Eigentümers, Mélanges W. Wołodkiewicz, Varsovie 2000, 277-302</p>
        <p>41. ‘Culpa in contrahendo’. Eine Geschichte der Wiederentdeckungen, w: U. Falk, H. Mohnhaupt (eds.), Das
            Bürgerliche Gesetzbuch und seine Richter, Frankfurt a.M. 2000, 113-154</p>
        <p>42. Vor-, Mit- und Nachdenker des Madagaskar-Plans, Rechtshistorisches Journal 19 (2000) 131-163</p>
        <p>43. Der Troubadour des Abendlandes, w: H. Schröder, D. Simon (eds.), Rechtsgeschichtswissenschaft in
            Deutschland 1945 bis 1952, Frankfurt a.M. 2001, 31-76</p>
        <p>44. Paul Koschaker sotto il nazismo: un fiancheggiatore &apos;malgré soi&apos;, Studi in onore di M.
            Talamanca IV, Napoli 2001, 159-187</p>
        <p>45. ‘Comparemus!’ Romanistica come fattore d&apos;unificazione dei diritti europei, Rivista Critica del
            Diritto Privato 19 (2001) 539-567</p>
        <p>46. Westen im Osten. Modernisierung osteuropäischer Rechte bis zum Zweiten Weltkrieg, Rechtsgeschichte 2
            (2003) 123-139</p>
        <p>47. Nießbrauchslegate zugunsten gemeinsamer Sklaven, Studies in honour of W. Litewski I, Kraków 2003,
            101-114</p>
        <p>48. Oriente e Occidente nella storia del diritto privato europeo, w: G. Alpa, R. Danovi (eds.), Diritto
            privato europeo. Fonti ed effetti, Mailand 2004, 343-357</p>
        <p>49. Osteuropäische Rechtsprechung im langen 19. Jahrhundert. Forschungsstrategische Vorschläge,
            http://www.rechtskulturen-osteuropa.net (24.09.2004) 1-7</p>
        <p>50. Tradizione giuridica dell’Europa orientale – un epitaffio, Ius Antiquum 14 (2004) 154-164</p>
        <p>51. Правовая традиция Восточной Европы: епитафия, w: Цивилистические исследования. Ежегодник гражданского
            права 2 (2005) 127–147 [= tłumaczenie rosyjskie pozycji nr 50]</p>
        <p>52. Wykładnia bez kodeksu, w: P. Winczorek (ed.), Teoria i praktyka wykładni prawa, Warszawa 2005, 13-27</p>
        <p>53. Problemi romani e problemi romanistici in tema di matrimonio, w: Z. Służewska, J. Urbanik (eds.),
            Marriage: Ideal – Law – Practice, Warszawa 2005, 83-110</p>
        <p>54. ‘Comparemus!’ Романистика как фактор унификации европейских правовых систем, Ius Antiquum 15 (2005)
            177-201 [= tłumaczenie rosyjskie pozycji nr 45]</p>
        <p>55. Судебная практика в Восточной Европе XIX века. Направления исследования, Ius Antiquum 15 (2005) 214-218
            [= tłumaczenie rosyjskie pozycji nr 49]</p>
        <p>56. Modernisierung durch Transfer – Schwund osteuropäischer Traditionen, w: T. Giaro (ed.), Rechtskulturen
            des modernen Osteuropa. Modernisierung durch Transfer im 19. und frühen 20. Jahrhundert, Frankfurt a.M.
            2006, 275-344</p>
        <p>57. Rzymski zakaz nadużycia praw podmiotowych w świetle nowej jurysprudencji pojęciowej, Zeszyty Prawnicze
            UKSW 6.1 (2006) 279-300</p>
        <p>58. ‘Оставим немцам эти детские отклонения!’ Догматика и история в польской романи- стической традиции, Ius
            Antiquum 18 (2006) 180-191 [= tłumaczenie rosyjskie poz. nr 34]</p>
        <p>59. Absurditätsargumente in der römischen Jurisprudenz. Ein Überblick, w: Orbis Iuris Romani 11 (2006)
            31-61</p>
        <p>60. Alt- und Neueuropa, Rezeptionen und Transfers, w: T. Giaro (ed.), Rechtskulturen des modernen Osteuropa.
            Modernisierung durch Transfer zwischen den Weltkriegen, Frankfurt a.M. 2007, 273-317</p>
        <p>61. ‘Abusus iuris’ a nadużycie prawa, Zeszyty Prawnicze UKSW 7.1 (2007) 273-291</p>
        <p>62. Interpretacja jako źródło prawa – dawniej i dziś, w: Studia Prawnoustrojowe Uniwersytetu
            Warmińsko-Mazurskiego 7 (2007) 243-253</p>
        <p>63. Diritto come prassi. Vicende del discorso giurisprudenziale, Studi in onore di L. Labruna, vol. IV,
            Napoli 2007, 2233-2261</p>
        <p>64. L’Occidente in Oriente. Modernizzazione del diritto nell’Europa dell’Est fino alla seconda guerra
            mondiale, Filia. Scritti per G. Franciosi, vol. III, Napoli 2007, 1075-1098 [= tłumaczenie włoskie pozycji
            nr 46]</p>
        <p>65. Запад на Востоке. Модернизация восточноевропейского права до Второй мировой войны, w: Цивилистические
            исследования. Ежегодник гражданского права 3 (2007) 173-199 [= tłumaczenie rosyjskie pozycji nr 46]</p>
        <p>66. Gibt es noch eine Wissenschaft vom Recht?, w: Leges sapere. Studia i prace dedykowane J. Sondlowi, Kraków
            2008, 155-169</p>
        <p>67. Kilka żywotów prawa rzymskiego zakończonych jego kodyfikacją, w: A. Dębiński, M. Jońca (eds.), Prawo
            rzymskie a kultura prawna Europy, Lublin 2008, 16-27</p>
        <p>68. Roman law always dies with a codification, w: A. Dębiński, M. Jońca (eds.), Roman Law and European Legal
            Culture, Lublin 2008, 15-26 [= tłumaczenie angielskie pozycji nr 67]</p>
        <p>69. Cywilizacja prawa rzymskiego i problemy współczesnej romanistyki, w: Acta Universitatis Wratislaviensis.
            Prawo CCCV. Pamięci Edwarda Szymoszka, Wrocław 2008, 69-78</p>
        <p>70. Racjonalność prawnicza jako sztuka kompromisu, w: M. Wyrzykowski (ed.), Rozumność ro- zumowań
            prawniczych, Warszawa 2008, 207-233</p>

        <div className='publicationsCenter'>
            <h4>Recenzje</h4>
        </div>
        <p>71. H. Kupiszewski, Quelques remarques sur le ‘parricidium’, Iura 23 (1972) 439</p>
        <p>72. J. Kurczewski, Prawo prymitywne, Państwo i Prawo 29.11 (1974) 155-158</p>
        <p>73. M. Ancel, Utilità e metodi del diritto comparato, Państwo i Prawo 30.7 (1975) 163-165</p>
        <p>74. P. Pinna Parpaglia, &apos;Aequitas&apos; in libera republica, Czasopismo Prawno-Historyczne 28.1 (1976)
            178-182</p>
        <p>75. A. Watson, Legal Transplants, Państwo i Prawo 32.5 (1977) 154-157</p>
        <p>76. M. Kuryłowicz, &apos;Adoptio&apos; prawa rzymskiego, Bullettino dell&apos;Istituto di Diritto Ro­mano 82
            (1979) 185-198</p>
        <p>77. M. Wurm, &apos;Apokeryxis&apos;, &apos;abdicatio&apos; und &apos;exheredatio&apos;, Journal of Juristic
            Papyrology 19 (1983) 199-204</p>
        <p>78. J. Axer, The Style and the Composition of Cicero&apos;s Speech &apos;Pro Q. Roscio comoedo&apos;, Index
            12 (1983-84) 566-569</p>
        <p>79. J. Pichler, &apos;Necessitas&apos;. Ein Element des mittelalterlichen und neuzeitlichen Rechts,
            Zeitschrift der Savigny-Stiftung RA 102 (1985) 739-751</p>
        <p>80. M. Ducos, Les Romains et la loi, Przegląd Historyczny 78 (1987) 303-309</p>
        <p>81. H. Alwart, Recht und Handlung. Die Rechtsphilosophie in ihrer Entwicklung, Ius Commune 16 (1989)
            356-360</p>
        <p>82. R. Astolfi, Il fidanzamento nel diritto romano, Zeitschrift der Savigny-Stiftung RA 108 (1991)
            484-488</p>
        <p>83. D. Liebs, Römische Jurisprudenz in Africa (pod tytułem: Pseudepigraph des Pseudopaulus),
            Rechtshistorisches Journal 13 (1994) 71-73</p>
        <p>84. P. Raisch, Juristische Methoden: vom antiken Rom bis zur Gegenwart (pod tytułem: Zufall in Pisa),
            Rechtshistorisches Journal 15 (1996) 441-446</p>
        <p>85. R. v.Jhering, Ist die Jurisprudenz eine Wissenschaft? (pod tytułem: Rechtswissenschaftliches aus der Welt
            3), Rechtshistorisches Journal 18 (1999) 649-658</p>
        <p>86. W. Leisner, Die Staatswahrheit (pod tytułem: Geist der massonischen Oberschicht), Rechtshistorisches
            Journal 19 (2000) 693-699</p>
        <p>87. M. Kaser, K. Hackl, Das römische Zivilprozeßrecht (wyd. 2), Klio 82 (2000) 565-566</p>
        <p>88. J. Habermas, Wahrheit und Rechtfertigung (pod tytułem: Rückzugsgefechte auf den Feldern der
            Wahrheitsanalogie), Rechtshistorisches Journal 20 (2001) 197-202</p>
        <p>89. J. Schröder, Recht als Wissenschaft (pod tytułem: Methodenreich), Rechtsgeschichte 1 (2002) 241-242</p>
        <p>90. R. Zimmermann, Roman Law, Contemporary Law, European Law (pod tytułem: Traditions- werkstatt
            Rechtsgeschichte), Rechtsgeschichte 1 (2002) 236-237</p>
        <p>91. H. Küpper, Einführung in die Rechtsgeschichte Osteuropas (pod tytułem: Station Sozialis- mus),
            Rechtsgeschichte 9 (2006) 204-208</p>
        <p>92. Katarzyna Sójka-Zielińska, Kodeks Napoleona. Historia i współczesność, Kwartalnik Prawa Prywatnego 17
            (2008) 303-306</p>
        <p>93. Marcin Zieliński, Der Transfer juristischen Gedankenguts innerhalb Europas, Czasopismo Prawno-Historyczne
            60.1 (2008) 314-317</p>

        <div className='publicationsCenter'>
            <h4>Hasła encyklopedyczne</h4>
        </div>
        <p>94. Aequitas, familia, interpretatio, manumissio, modus, pater familias, patria potestas, patronus, peculium,
            pecunia, status, sui iuris, transmissio, tutela, utilitas, verba, voluntas, w: Z. Rybicki (ed.), Mała
            Encyklopedia Prawa, Warschau 1980</p>
        <p>95. Quintus Mucius Scaevola, Sextus Pomponius, w: A. Świderkówna (ed.), Słownik Pisarzy Antycz­nych, Warschau
            1982</p>
        <p>96. Koschembahr-Łyskowski, Longchamps de Berrier, Maciejowski, Till, Wrόblewski, Zoll junior, Zoll senior, w:
            M. Stolleis (ed.), Juristen. Ein biographisches Lexikon, Mün­chen 1995 (wyd. 2, München 2001)</p>
        <p>97. Aelius Gallus, Aelius Catus, Aelius Marcianus, Aemilius Macer, Alfenus Varus, Ana­tolios, Antistius
            Labeo, Aquilius Gallus, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly I, Stuttgart-Weimar 1996</p>
        <p>98. Arulenus, Ateius Capito, Atilicinus, Aufidius Namusa, Caecilius Africanus, Callistra­tus, Cascellius,
            Cassius, Charisius, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly II, Stuttgart-Weimar 1997</p>
        <p>99. Claudius Saturninus, Coruncanius Tiberius, Dorotheos, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly
            III, Stuttgart-Weimar 1997</p>
        <p>100. Fabius Mela, Flavius Gnaeus, Florentinus, Fufidius, Fulcinius, Fulvius Valens, Furius Anthianus, Gaius,
            Gregorius, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly IV, Stuttgart-Weimar 1998</p>
        <p>101. Hermogenianus, Iavolenus Priscus, interpretatio, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly V,
            Stuttgart-Weimar 1998</p>
        <p>102. Iulianus, Iulius Aquila, Iulius Paulus, Iunius Brutus, Iunius Mauricianus, iuris consultus, iuris
            prudentia, Iuventius Celsus, Kratinos, Kyrillos, Laelius, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly
            VI, Stuttgart-Weimar 1999</p>
        <p>103. Leontios, Licinius Rufinus, Livius Drusus, Marinianus, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly
            VII, Stuttgart-Weimar 1999</p>
        <p>104. Minicius, Modestinus, Mucius Publius, Mucius Quintus, Neratius, Octavenus, Ofilius, w: H. Cancik, H.
            Schneider (eds.), Der Neue Pauly VIII, Stuttgart-Weimar 2000</p>
        <p>105. Pactumeius, Papinianus, Papirius Fronto, Papirius Iustus, Patrikios, Pedius, Pegasus, Plautius, w: H.
            Cancik, H. Schneider (eds.), Der Neue Pauly IX, Stuttgart-Weimar 2001</p>
        <p>106. Pomponius, Porcius Cato, Proculus, Rechtsschulen, Responsa, Rutilius Maximus, Rutilius Rufus, Sabinus,
            w: H. Cancik, H. Schneider (eds.), Der Neue Pauly X, Stuttgart-Weimar 2001</p>
        <p>107. Scaevola, Servius, Stephanos, w: H. Cancik, H. Schneider (eds.), Der Neue Pauly XI, Stuttgart-Weimar
            2001</p>
        <p>108. Taruttienus, Terentius, Tertullianus, Thalelaios, Theodoros, Theophilos, Titius Aristo, Trebatius,
            Tribonianus, Tryphoninus, Tuscianus, Ulpianus, Ulpius Marcellus, Urseius, w: H. Cancik, H. Schneider (eds.),
            Der Neue Pauly XII.1, Stuttgart-Weimar 2002</p>
        <p>109. Venuleius, Vindius Verus, Vivianus, Volusius Maecianus, w: H. Cancik, H. Schneider (eds.), Der Neue
            Pauly XII.2, Stuttgart-Weimar 2002</p>
        <p>110. Pozycje 97-109 istnieją również w tłumaczeniu angielskim „Brill&apos;s New Pauly”, vol. I-XV, Leyden
            2002-2010</p>
        <p>111. Rafał Taubenschlag 1881-1958, w: G. Bałtruszajtys (ed.), Profesorowie Wydziału Prawa i Administracji
            1808-2008, Warszawa 2008, 210-213</p>

        <div className='publicationsCenter'>
            <h4>Sprawozdania naukowe</h4>
        </div>
        <p>112. Il colloquio internazionale di Popowo, Index 9 (1980) 315-321</p>
        <p>113. La nuova Commissione per i Diritti Antichi dell&apos;Accademia Po­lacca delle Scienze, In­dex 12
            (1983-84) 555-557</p>
        <p>114. Elementi romanistici nella cultura giuridica europea, Index 14 (1986) 383-385</p>

        <div className='publicationsCenter'>
            <h4>Tłumaczenia</h4>
        </div>
        <p>115. A. Wolter, Diritto civile polacco. Parte generale, Camerino 1976, stron 371 (z polskiego na włoski, przy
            współudziale C. Zawadzkiej i L. Lonardo)</p>
        <p>116. W. Czachórski, Il diritto delle obbligazioni, Camerino 1980, stron 419 (z polskiego na włoski, przy
            współudziale C. Zawadzkiej i R. Pane)</p>

        <div className='publicationsCenter'>
            <h4>Inne</h4>
        </div>
        <p>117. In memoriam Henryk Kupiszewski, Zeitschrift der Savigny-Stiftung RA 112 (1995) 737-739</p>
        <p>118. [Wydawca tomu] Rechtskulturen des modernen Osteuropa, tom I. Modernisierung durch Transfer im 19. und
            frühen 20. Jahrhundert, Frankfurt a.M. 2006, stron 344</p>
        <p>119. [Wydawca tomu] Rechtskulturen des modernen Osteuropa, tom II. Modernisierung durch Transfer zwischen den
            Weltkriegen, Frankfurt a.M. 2007, stron 317</p>
        <p>120. Vorwort, w: Z. Pokrovac (ed.), Juristenausbildung in Osteuropa bis zum Ersten Weltkrieg, Frankfurt a.M.
            2007, VII-VIII</p>

        <div className='publicationsCenter'>
            <h3>Po nadaniu tytułu naukowego profesora</h3>
        </div>

        <div className='publicationsCenter'>
            <h4>Książki</h4>
        </div>
        <p>121. Prawo rzymskie. U podstaw prawa prywatnego, Warszawa 2009, stron 584 (wspólnie z Wojciechem Dajczakiem i
            Franciszkiem Longchamps de Bérier); wyd. 2, Warszawa 2014, stron 592</p>
        <p>122. Právo římské. Základy soukromého práva, Tłumaczenie czeskie podręcznika „Prawo rzymskie… [nr 121],
            Olomouc 2013, stron 423</p>
        <p>123. Prawo rzymskie. Trener akademicki, Warszawa- Bielsko Biała 2010, stron 198 (wspólnie z Wojciechem
            Dajczakiem i Franciszkiem Longchamps de Bérier)</p>
        <p>124. Warsztaty prawnicze. Prawo rzymskie, Bielsko Biała 2012, stron 200 (wspólnie z Wojciechem Dajczakiem i
            Franciszkiem Longchamps de Bérier)</p>
        <p>125. Prawo rzymskie. U podstaw prawa prywatnego, Warszawa 2018, wyd. 3 rozszerzone (wspólnie z Wojciechem
            Dajczakiem i Franciszkiem Longchamps de Bérier), stron 745</p>

        <div className='publicationsCenter'>
            <h4>Artykuły</h4>
        </div>
        <p>126. Leon Petrażycki i ekonomiczna analiza prawa. Materiały do dalszych rozważań, w: H. Izdebski (ed.), Nauka
            i nauczanie prawa. Tradycja i przyszłość, Warszawa 2009, 211-239</p>
        <p>127. Dal soft law moderno al soft law antico, w: A. Somma (ed.), Soft law e hard law nelle società
            postmoderne, Torino 2009, 83-99</p>
        <p>128. Леон Петражицкий – основоположник экономического анализа права, w: &quot;ЭПОС&quot;. Экономика.
            Предпринимательство. Окружающая среда 38 (2009) 71-79</p>
        <p>129. Леон Петражицкий и экономический анализ права, w: Научные труды Московской академии экономики и права 24
            (2009) 454-486 [= tłumaczenie rosyjskie poz. nr 125]</p>
        <p>130. Wartości w języku prawnym i dyskursie prawniczym, w: Preambuła Konstytucji Rzeczpospolitej Polskiej.
            Wydawnictwa Trybunału Konstytucyjnego, Warszawa 2009, 13-21</p>
        <p>131. Wartość prawna wolności i jej źródła antyczne, w: E. Kozerska et al. (eds.), Idea wolności w ujęciu
            historycznym i prawnym, Toruń 2010, 13-27</p>
        <p>132. Czy Rzymianie byli ludem prawa? Problemy porównań diachronicznych, w: J. Przygodzki, M.J. Ptak (eds.),
            Społeczeństwo, a władza. Ustrój, prawo, idee, Wrocław 2010, 115-129</p>
        <p>133. Czy w przewidywalnej przyszłości powstanie europejskie prawo zobowiązań?, Forum Prawnicze 1 (2010)
            66-88</p>
        <p>134. Prawo handlowe czy gospodarcze? Kilka modeli historycznych, w: Prawo handlowe XXI wieku. Księga
            Jubileuszowa J. Okolskiego, Warszawa 2010, 166-182</p>
        <p>135. Европейская история частного права: инструмент унификации права и продукт смешения категорий, w:
            Цивилистические исследования. Ежегодник гражданского права 4 (2010) 10-57 [= tłumaczenie rosyjskie pozycji
            nr 27]</p>
        <p>136. Право и история права в эпоху глобализации, w: Перспективы взаимодействия национальных правовых систем в
            условиях глобализации и регионализации, Rostów nad Donem 2010, 25-28 [skrócona wersja rosyjska pozycji nr
            137]</p>
        <p>137. Prawo i historia prawa w dobie globalizacji, w: T. Giaro (ed.), Prawo w dobie globalizacji, Warszawa
            2011, 73-88</p>
        <p>138. Afrikan und die reductio ad absurdum, w: J.D. Harke (ed.), Africani Quaestiones, Berlin-Heidelberg 2011,
            1-15</p>
        <p>139. Legal Tradition of Eastern Europe. Its Rise and Demise, Comparative Law Review (Special Issue: The
            Construction of Legal Traditions) 2.1 (2011) 1-23</p>
        <p>140. Knowledge of Law as Knowledge of Facts, w: T. Giaro (ed.), Roman Law and Legal Knowledge, Warszawa 2011,
            215-241</p>
        <p>141. Rzymskie prawdy prawnicze. Eksperyment myślowy, w: Księga Pamiątkowa T. Erecińskiego, Warszawa 2011,
            2959-2982</p>
        <p>142. Tradition und Geschichte als Grenzen der Auslegungsfreiheit im Privatrecht, w: J. Stelmach, R. Schmidt
            (eds.), Krakauer-Augsburger Rechtsstudien, Warszawa 2011, 223-241</p>
        <p>143. Praxeologische Folgerungen in der römischen Jurisprudenz, w: Homenaje A. Guzmán Brito, vol. II,
            Alessandria 2011, 611-628</p>
        <p>144. Krótka historia istoty osoby prawnej, w: Księga Pamiątkowa Andrzeja Kremera, Warszawa 2012, 59-74</p>
        <p>145. Prawdy prawa i prawdy prawnicze. Trzy pytania historyka, w: Abiit, non obiit. Księga Pamiątkowa Ks.
            Prof. Antoniego Kościa, Lublin 2013, 901-915</p>
        <p>146. Papinian und die reductio ad absurdum, w: J.D. Harke (ed.), Argumenta Papiniani, Berlin-Heidelberg 2013,
            41-57</p>
        <p>147. Comparing German and Polish private law. Preliminary notes, Studia Iuridica 56 (2013) 9-15</p>
        <p>148. Der allgemeine Teil: Physik und Metaphysik. Zivilistik in Zeiten der Dekodifikation, w: Ch. Baldus, W.
            Dajczak (eds.), Der allgemeine Teil des Privatrechts, Frankfurt a.M. 2013, 47-68</p>
        <p>149. Verità fattuale e verità normativa nell’argomentazione della giurisprudenza romana, w: ‘Quid est
            veritas?’ Un seminario sulla ‘veritas’ e forme giuridiche, Napoli 2013, 359-380</p>
        <p>150. Some Prejudices about the Legal Tradition of Eastern Europe, w: B. Sitek et al. (eds.), Comparative Law
            in Eastern and Central Europe, Cambridge 2013, 26-50</p>
        <p>151. The East of the West. Harold J. Berman and Eastern Europe, Rechtsgeschichte 21 (2013) 193-197</p>
        <p>152. Fokus, czyli o dyscyplinach pomocniczych na przykładzie romanistyki, w: T. Giaro (ed.), Dziedziny prawa,
            dyscypliny i metody prawnicze, Warszawa 2013, 189-194</p>
        <p>153. Europejska geneza polskich zasad i wartości konstytucyjnych, Państwo Prawne 3 (2013) 7-17</p>
        <p>154. Transfers von Traditionen. Zum Rechtswechsel auf dem Balkan, Studia Iuridica 58 (2014) 95-104</p>
        <p>155. Geleneklerin aktarimi: Balkanlardaki Hukuk Değişimi Üzerine [= tłumaczenie tureckie poprzedniej pozycji]
            Istanbul Kültür Üniversitesi Hukuk Fakültesi Dergisi 14.1 (2015) 111-119</p>
        <p>156. Najstarszy system prywatnoprawnej ochrony słabszego, w: Księga Jubileuszowa Prof. Adama Zielińskiego,
            Warszawa 2016, 55-72</p>
        <p>157. Moment historyczny w prawoznawstwie porównawczym, w: A. Wudarski (red.), Polska komparatystyka prawa,
            Warszawa 2016, 37-62</p>
        <p>158. L&apos;expérience de l&apos;absurde chez les juristes romains, w: Mater familias. Scritti romanistici
            per Maria Zabłocka, Warszawa 2016, 243-267</p>
        <p>159. Петражицкий и ранняя история law & economics, w: Г.Ф. Гараева, Я. Турлуковски (ed.), Мысль Л.И.
            Петражицкого и современная наука права, Краснодар 2016, 128-149</p>
        <p>160. От современного soft law к античному soft law, Правоведение = Pravovedenie 2 (2016). Известия высших
            учебных заведений, s. 198-219 [tłumaczenie rosyjskie pozycji nr 126]</p>
        <p>161. Transnational Law and its Historical Precedents, Studia Iuridica 68 (2016) 73-85</p>
        <p>162. La Polonia nella cultura giuridica europea. Abbozzo storico, Atti dell&apos;Accademia Polacca di Roma 5
            (2016) 35-51</p>
        <p>163. Dekodyfikacja. Uwagi historyczno-teoretyczne, w: F. Longchamps de Berier (ed.), Dekodyfikacja prawa
            prywatnego. Szkice do portretu, Warszawa 2017, 13-37</p>
        <p>164. Paradoks suwerenności i zjawiska pokrewne. Przegląd zagadnień, w: Jan Majchrowski (ed.), W obronie
            suwerenności, Warszawa 2017, 19-33</p>
        <p>165. La cultura giuridica dell’Europa orientale. Fondamenti storici, w: E. Castorina (red.), Servizi
            pubblici, diritti fondamentali, costituzionalismo europeo, Napoli 2017, 23-38</p>
        <p>166. Przyszłość prawa dawniej i dziś, w: B. Hołyst (ed.), Przyszłość prawa. Księga Pamiątkowa XX-lecia
            Wydziału Prawa i Administracji Uczelni Łazarskiego, Warszawa 2017, 27-37</p>
        <p>167. Leon Petrażycki jako prekursor law & economics, Studia Iuridica 74 (2018) 135-154</p>
        <p>168. Prawo administracyjne w starożytnym Rzymie?, w: Prawo administracyjne wobec współczesnych wyzwań. Księga
            Jubileuszowa M. Wierzbowskiego, Warszawa 2018, 5-14</p>
        <p>169. Wschód i Zachód jako wartości polskiej tradycji polityczno-prawnej, Forum Prawnicze 45 (2018) 3-18</p>
        <p>170. In Defence of Decodification. A Discussion of the Concept and its Consequences, w: Chen Su, P. Grzebyk,
            F. Longchamps de Berier (red.), Theory and Practice of Codification. The Chinese and Polish perspective, The
            China Social Sciences Press 2019, 3-17</p>
        <p>171. Roman Law and Consensual Rationality, w: Scritti per il novantesimo compleanno di Matteo Marrone,
            Palermo 2019, 137-153</p>
        <p>172. Legal Historians and the Eastern Border of Europe, w: Tommaso Beggio, Aleksander Grebieniow (eds.),
            Methodenfragen der Romanistik im Wandel, Tübingen 2020, 145-162</p>
        <p>173. Civilpolitik Petrażyckiego, czyli ideał miłości w gospodarce zdecentralizowanej, w: T. Giaro (ed.), Leon
            Petrażycki i współczesna nauka prawa, Warszawa 2020, 103-210</p>
        <p>174. Petrażyckis Civilpolitik oder das Liebesideal in der dezentralisierten Wirtschaft, w: T. Giaro (ed.),
            Leo von Petrażycki und die Rechtswissenschaft der Gegenwart, Warszawa 2020, 103-214</p>
        <p>175. Гражданскаяа политика Петражицкого, или идеал любви в децентрализованной экономике, w: J. Turłukowski
            (ed.), Лев Петражицкий и современная юриспруденция, Warszawa 2020, 105-223</p>
        <p>176. Law’s Future, Yesterday and Today, w: Weltbürgerliches Recht. Festschrift für Michael Martinek, München
            2020, 217-228</p>
        <p>177. Orzecznictwo sądowe między samowolą a robotyzacją, w: Profesor Marek Safjan znany i nieznany. Księga
            Jubileuszowa, Warszawa 2020, 135-139</p>
        <p>178. Proces prawnego różnicowania a początki prawa pracy, Praca i Zabezpieczenie Społeczne 61.6 (2020)
            3-7</p>
        <p>179. The Law of Contract viewed from the Historical Context of the Civil Law Tradition, w: P. Grzebyk, E.
            Rott-Pietrzyk, Chen Su (eds.), Sino-Polish Perspectives on the Theory and Practice of Contract Law, Warsaw
            2020, 15-34</p>
        <p>180. Pochwała dekodyfikacji, w: Z zagadnień systemu prawa. Księga Jubileuszowa Prof. Pawła Czechowskiego,
            Warszawa 2021, 243-257</p>
        <p>181. Co prawu administracyjnemu może dać historia prawa? w: Kryzys, stagnacja, renesans? Prawo
            administracyjne przyszłości. Księga Pamiątkowa Prof. Jacka Jagielskiego, Warszawa 2021, 31-40</p>
        <p>182. Medieval Canon Lawyers and European Legal Tradition, Review of European and Comparative Law 47.4 (2021)
            157-187</p>
        <p>183. “Provisionally dead”. Roman law and juristic papyrology in interwar Poland, w: A. Gallo, L. Mecella, P.
            Buongiorno (eds.) Segmenti della ricerca antichistica e giusantichistica negli anni Trenta, vol II, Napoli
            2022, 667-721</p>
        <p>184. Metamorfozy władzy sądowniczej, w: Między ideowością a pragmatyzmem - tworzenie, wykładnia i stosowanie
            prawa. Księga Jubileuszowa Prof. Małgorzaty Gersdorf, Warszawa 2022, 1170-1186</p>
        <p>185. Nauka o prawie a prawo o nauce. Dwie feralne recenzje Propagandy bezprawia Jarosława Kuisza, Studia
            Iuridica 91 (2022) 82-89</p>
        <p>186. More Breaking News from Nazi Times. Is the European Legal Tradition Spurious?, Studia Iuridica 95 (2022)
            140-149</p>
        <p>187. Jhering and Politics, w: Stephan Meder, Christoph-Eric Mecke (eds.) Jhering Global. Internationales
            Symposium zum 200. Geburtstag Rudolf von Jherings (1818-1892), Göttingen 2023, 57-69</p>
        <p>188. The Wave of Transfers: An East-European Chapter in the Civil Law Tradition, Comparative Law Review 14.2
            (2023) 33-58</p>
        <p>189. A Purely Western Tradition. The 100th anniversary of the Orientalization Debate, in The Grand Strategy
            of Comparative Law: Themes, Methods, Developments. Essays in Honour of Pier Giuseppe Monateri, Routledge
            2024, 253-267</p>
        <p>190. What is the European Legal Tradition? When, Why and by Whom was it Invented?, in Tommaso Beggio, Paul Du
            Plessis (eds.) Teaching Roman Law, 2024 [w druku]</p>
        <p>191. Upływ czasu a prawo: jego dogmatyka, historia i tradycja, w O tempora, o mores! Księga Jubileuszowa
            Krzysztofa Rączki [w druku]</p>
        <p>192. Europe’s Medievalistic Genealogies in Legal History. Have They Rivals?, in Johannes Liebrecht, Dag
            Michelsen (eds.) [w druku]</p>

        <div className='publicationsCenter'>
            <h4>Recenzje</h4>
        </div>
        <p>193. Massimo Miglietta, Gianni Santucci (eds.), Diritto romano e regimi totalitari nel ’900 europeo,
            Zeitschrift der Savigny-Stiftung RA 128 (2011) 706-712</p>
        <p>194. Francisco Javier Andrés Santos, Christian Baldus, Helge Dedek (eds.), Vertragstypen in Europa (pod
            tytułem: Rozróżnianie i mieszanie umów nazwanych), Forum Prawnicze 13 (2012) 61-63</p>
        <p>195. Federico Maria d&apos;Ippolito, Saggi di storia della storiografia romanistica, Zeitschrift der
            Savigny-Stiftung RA 130 (2013) 752-753</p>
        <p>196. Mario Varvaro, Le istituzioni di Gaio e il Glücksstern di Niebuhr, Eos 100 (2013) 366-368</p>
        <p>197. Maciej Jońca, Przestępstwo znieważenia grobu w prawie rzymskim (pod tytułem: Bezczeszczenie grobów w
            Rzymie a interes polskiego podatnika), Forum Prawnicze 20 (2013) 51-57</p>
        <p>198. Piotr Niczyporuk, Bankierzy i operacje bankierskie w starożytnym Rzymie (pod tytułem: Ex Oriente lux?
            Pierwsza polska monografia o bankierach rzymskich i ich operacjach), Forum Prawnicze 22 (2014) 52-61</p>
        <p>199. Daniele Stasi, Leon Petrażycki. La vita ed il pensiero (pod tytułem: Życie i myśl prawnika o nazwisku
            Petrażcyki – tym razem po włosku), Forum Prawnicze 24 (2014) 55-61</p>
        <p>200. Martin Avenarius, Fremde Traditionen des römischen Rechts (pod tytułem: Russia and Roman Law),
            Rechtsgeschichte 23 (2015) 309-319</p>
        <p>201. Tommaso Beggio, Paul Koschaker 1879-1951 (pod tytułem: Memory Disorders: Koschaker Rediscovered and
            Baudlerized), Studia Iuridica 78 (2018) 9-23</p>
        <p>202. Tommaso Beggio, Paul Koschaker 1879-1951 (pod tytułem: Les troubles de la mémoire : Koschaker,
            redécouvert et expurgé), Grief. Revue sur les mondes du droit 6.2 (2019) 73-86</p>
        <p>203. Ville Erkkilä, The Conceptual Change of Conscience. Franz Wieacker and German Legal Historiography
            1933–1968 (pod tytułem: A Matter of Pure Conscience? Franz Wieacker and his Conceptual Change), Studia
            Iuridica 82 (2019) 9-28</p>
        <p>204. A Matter of Pure Conscience? Franz Wieacker and his Conceptual Change, Comparative Law Review 10.2
            (2019) 106-123</p>
        <p>205. Jörg Benedict, Culpa in contrahendo, Zeitschrift der Savigny-Stiftung GA 137 (2020) 546-548</p>
        <p>206. The Culmination-Book. Trying to Make Sense of the Nazi Years, Studia Iuridica 83 (2020) 7-26</p>
        <p>207. Victims and Supporters of Nazism vis-à-vis Europe’s Legal Tradition, Forum Prawnicze 62.6 (2020)
            67-86</p>
        <p>208. Breaking News from Nazi Times. Some Roman Lawyers Whitewashed!, Academia Letters (March 2021), article
            425, 1-5</p>
        <p>209. Eilmeldung aus der Nazizeit. Einige Romanisten reingewaschen!, Myops. Berichte aus der Welt des Rechts
            43 (2021) 51-56</p>

        <div className='publicationsCenter'>
            <h4>Hasła encyklopedyczne</h4>
        </div>
        <p>210. Poland, w: S.N. Katz (ed.), The Oxford International Encyclopedia of Legal History, vol. IV, Oxford-New
            York 2009, 330-332</p>
        <p>211. Ius et lex (126-128), Prawo a historia (339-342), Recepcja prawa (398-410), w: A. Kojder, Zb. Cywiński
            (eds.), Socjologia prawa. Główne problemy i postacie, Warszawa 2014</p>
        <p>212. Die Lehre vom Einkommen, w: S. Dauchy et al. (eds.), The Formation and Transmission of Western Legal
            Culture. 150 Books that Made the Law in the Age of Printing, Paris 2016, 416-419</p>

        <div className='publicationsCenter'>
            <h4>Nekrologi</h4>
        </div>
        <p>213. Przemówienie przygotowane do wygłoszenia na pogrzebie Prof. Michała Kuleszy, Studia Iuridica 58 (2014)
            343-344</p>
        <p>214. Przemówienie wygłoszone na pogrzebie Prof. Anny Turskiej, Studia Iuridica 59 (2014) 393-394</p>

        <div className='publicationsCenter'>
            <h4>Inne</h4>
        </div>
        <p>215. Od redaktora, w: T. Giaro (ed.), Skuteczność prawa, Warszawa 2010, 7</p>
        <p>216. [Wydawca tomu] Skuteczność prawa, Warszawa 2010, stron 275</p>
        <p>217. Podsumowanie, w: T. Giaro (ed.), Prawo w dobie globalizacji, Warszawa 2011, 273-274</p>
        <p>218. [Wydawca tomu] Prawo w dobie globalizacji, Warszawa 2011, stron 275</p>
        <p>219. Foreword, w: T. Giaro (ed.), Roman Law and Legal Knowledge, Warszawa 2011, 7-8</p>
        <p>220. [Wydawca tomu] Roman Law and Legal Knowledge, Warszawa 2011, stron 241</p>
        <p>221. Od redaktora (9-10) i Podsumowanie (275-277), w: T. Giaro (ed.), Interes publiczny a interes prywatny w
            prawie, Warszawa 2012</p>
        <p>222. [Wydawca tomu] Interes publiczny a interes prywatny w prawie, Warszawa 2012, stron 279</p>
        <p>223. Wstęp do: Henryk Kupiszewski, Prawo rzymskie a współczesność, Warszawa 2013, 7-11 (wspólnie z
            Franciszkiem Longchamps de Bérier)</p>
        <p>224. [Wydawca tomu] Dziedziny prawa, dyscypliny i metody prawnicze, Warszawa 2013, stron 212</p>
        <p>225. Otwarcie konferencji (9-10) i Podsumowanie (209-212) w: T. Giaro (ed.), Dziedziny prawa, dyscypliny i
            metody prawnicze, Warszawa 2013</p>
        <p>226. A New Warsaw Journal of Comparative Law. An Item for the Agenda, w: University of Warsaw Journal of
            Comparative Law 1 (2014) 1-3</p>
        <p>227. [Wydawca tomu] Ekonomiczna analiza prawa, Warszawa 2015, stron 270</p>
        <p>228. Podsumowanie i zamknięcie konferencji, w: T. Giaro (ed.), Ekonomiczna analiza prawa, Warszawa 2015,
            267-270</p>
        <p>229. [Wydawca tomu] Rola orzecznictwa w systemie prawa, Warszawa 2016, stron 380</p>
        <p>230. Podsumowanie konferencji, w: T. Giaro (ed.), Rola orzecznictwa w systemie prawa, Warszawa 2016,
            377-380</p>
        <p>231. Wypowiedź w panelu The Law School of the Future, w: Legal Education and Legal Profession in the Global
            World. Polish-American Perspectives, Studia Iuridica 62 (2016) 45-48</p>
        <p>232. Upadłość państw, odszkodowania karne i zasada kauzalności, Studia Iuridica 64 (2016) 123-125</p>
        <p>233. [Wydawca tomu] Źródła prawa. Teoria i praktyka, Warszawa 2016, stron 393</p>
        <p>234. Podsumowanie konferencji, w: T. Giaro (ed.), Źródła prawa. Teoria i praktyka, Warszawa 2016, 387-393</p>
        <p>235. Preface, w: Studia Iuridica 71 (2017) 9-10</p>
        <p>236. [Wydawca tomu] Leon Petrażycki i współczesna nauka prawa, Warszawa 2019, stron 358</p>
        <p>237. Wprowadzenie w: T. Giaro (ed.), Leon Petrażycki i współczesna nauka prawa, Warszawa 2020, 9-11</p>
        <p>238. [Wydawca tomu] Leo von Petrażycki und die Rechtswissenschaft der Gegenwart, Warszawa 2020, stron 358</p>
        <p>239. Einleitung w: Leo von Petrażycki und die Rechtswissenschaft der Gegenwart, Warszawa 2020, 9-11</p>
        <p>240. Foreword from the editor-in-chief. Double anniversary: 100 issues and half a century of ‘Studia
            Iuridica’, w: Studia Iuridica 100 (2023) 7-10</p>

    </div>);

    // const [showPdf, setShowPdf] = useState(false);

    return (<section className='section publications center' id='publications'>
        <div className='infoSections'>
            <div className='infoSection'>
                <Collapsible
                    trigger='Publikacje'
                    triggerClassName='infoTrigger'
                    triggerOpenedClassName='infoTrigger'
                    contentInnerClassName='infoContent'
                    contentOuterClassName='infoOuter'
                >
                    {content}
                </Collapsible>
            </div>
        </div>
        <div className='infoSections'>
            <div className='infoSection'>
                <Collapsible
                    trigger='Publikacje - pdf'
                    triggerClassName='infoTrigger'
                    triggerOpenedClassName='infoTrigger'
                    contentInnerClassName='infoContent'
                    contentOuterClassName='infoOuter'
                >
                    <BrowserView>
                        <iframe src={pdf} title='Publikacje' className='pdf'/>
                    </BrowserView>
                    <MobileView>
                        <iframe src={pdf} title='Publikacje' className='pdf'/>
                    </MobileView>
                </Collapsible>
            </div>
        </div>
    </section>)
}

export default Publications
