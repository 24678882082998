import './ContactFooter.css'
import fb from '../../assets/fb.png';
import linked from '../../assets/linked.png';
import mail from '../../assets/mail.jpg';
import academic from '../../assets/academic.png';

const ContactFooter = () => {
    ;
    return (
        <section className='section contactFooter center' id='contactFooter'>
            <h4>Kontakt</h4>
            <div className='conCon'>
                <div className='contactDiv'>
                    <div className='contactElement'>
                        <img src={fb} alt='fb' className='fbImage'/>
                    </div>
                    <div className='contactElement'>
                        <a href='https://www.facebook.com/tomaszgiaro'>facebook.com/tomaszgiaro</a>
                    </div>
                </div>
                <div className='contactElement'>  </div>
                <div className='contactDiv'>
                    <div className='contactElement'>
                        <img src={linked} alt='linked' className='fbImage'/>
                    </div>
                    <div className='contactElement'>
                        <a href='https://www.linkedin.com/in/tomasz-giaro-51189359'>Tomasz Giaro</a>
                    </div>
                </div>
                <div className='contactElement'>  </div>
                <div className='contactDiv'>
                    <div className='contactElement'>
                        <img src={mail} alt='mail' className='fbImage'/>
                    </div>
                    <div className='contactElement'>
                        <p>giaro@uw.edu.pl</p>
                    </div>
                </div>
                <div className='contactElement'>  </div>
                <div className='contactDiv'>
                    <div className='contactElement'>
                        <img src={academic} alt='academic' className='fbImage'/>
                    </div>
                    <div className='contactElement'>
                        <a href='https://uw.academia.edu/TomaszGiaro'>Academia</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactFooter
