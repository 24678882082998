import './AboutMe.css'
import mainImage from '../../assets/main.jpg';

const AboutMe = () => {
    ;
    return (
        <section className='section aboutMe center' id='aboutMe'>
            <div className='aboutDiv'>
                <div className='aboutName'>
                    <h5>prof. dr hab.</h5>
                    <h1>Tomasz Giaro</h1>
                    <h5>Kandydat na Dziekana Wydziału Prawa i Administracji Uniwersytetu Warszawskiego</h5>
                    <br />
                    <p>
                        Profesor nauk prawnych, polski prawnik,<br />
                        specjalista w zakresie prawa rzymskiego,<br />
                        teorii, filozofii i historii prawa oraz prawa porównawczego
                    </p>
                </div>

                <div className='imageDiv'>
                    <img className='mainImage' src={mainImage} alt="zdjęcie główne"/>
                </div>
            </div>
        </section>
    )
}

export default AboutMe
